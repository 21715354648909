import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import MediaQuery from "react-responsive";

import Layout from "../components/Layout";
import Button from "../components/Button";
import Features from "../components/Features";

import planetOne from "../img/planet1.svg";
import planetTwo from "../img/planet2.svg";
import planetThree from "../img/planet3.svg";
import satellite from "../img/satellite.svg";
import meteorOne from "../img/meteor.svg";

import "./index-page.sass";

export const IndexPageTemplate = ({
  intro,
  disclaimer,
  purchaseSteps,
  desktopLogo,
  mobileLogo,
  helmet,
  groundFloorDesktop,
  groundFloorMobile
}) => {
  return (
    <div className="index-page">
      {helmet || ""}

      <section className="introduction">
        <div className="asset-wrap">
          <div className="planet-one spinning">
            <img src={planetOne} alt="" />
          </div>
          <div className="planet-two spinning">
            <img src={planetTwo} alt="" />
          </div>
          <div className="planet-three spinning">
            <img src={planetThree} alt="" />
          </div>
          <div className="satellite bounce">
            <img src={satellite} alt="" />
          </div>
          <div className="meteor-one shooting">
            <img src={meteorOne} alt="" />
          </div>
          <div className="meteor-two shooting">
            <img src={meteorOne} alt="" />
          </div>
          <div className="meteor-three shooting">
            <img src={meteorOne} alt="" />
          </div>
          <div className="meteor-four shooting">
            <img src={meteorOne} alt="" />
          </div>
          <div className="dot dot-one" />
          <div className="dot dot-two" />
          <div className="dot dot-three" />
          <div className="dot dot-four" />
          <div className="dot dot-five" />
          <div className="dot dot-six" />
        </div>

        <div className="container">
          <div className="top-logo">
            <MediaQuery query="(min-width: 769px)">
              <Img
                className="logo"
                fluid={desktopLogo.childImageSharp.fluid}
                alt="100 Story Building"
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 768px)">
              <Img
                className="logo"
                fluid={mobileLogo.childImageSharp.fluid}
                alt="100 Story Building"
              />
            </MediaQuery>
          </div>

          <p>{intro.sectionOne}</p>
          <p>{intro.sectionTwo}</p>
        </div>
        <h5 className="tax-message-one">
          All donations above $2 are tax deductible.
        </h5>
      </section>

      <section className="cloud-break" />

      <section className="cloud-flex">
        <div className="container">
          <div className="disclaimer">
            <h3 className="">{disclaimer.heading}</h3>
            <p>{disclaimer.description}</p>
          </div>

          <div className="button-wrap">
            <Button linkRef="/levels">View Levels</Button>
          </div>
        </div>
      </section>

      <section className="main-img-wrap">
        <MediaQuery query="(min-width: 521px)">
          <Img
            className="logo"
            fluid={groundFloorDesktop.childImageSharp.fluid}
            alt="100 Story Building Ground Floor"
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 520px)">
          <Img
            className="logo"
            fluid={groundFloorMobile.childImageSharp.fluid}
            alt="100 Story Building Ground Floor"
          />
        </MediaQuery>
      </section>

      <section className="purchase-steps">
        <div className="container">
          <div className="steps">
            <h3 className="">{purchaseSteps.heading}</h3>
            <Features gridItems={purchaseSteps.blurbs} />
            <p className="desc">{purchaseSteps.description}</p>
          </div>

          <div className="button-wrap">
            <Button linkRef="/levels">View Levels</Button>
          </div>
        </div>
      </section>
    </div>
  );
};

const IndexPage = ({ data, location: { href } }) => {
  const { frontmatter } = data.markdownRemark;
  const {
    mobileLogo,
    desktopLogo,
    groundFloorDesktop,
    groundFloorMobile
  } = data;

  return (
    <Layout currentPageUrl={href} pageName="home-page" navLink="about">
      <IndexPageTemplate
        intro={frontmatter.intro}
        disclaimer={frontmatter.disclaimer}
        purchaseSteps={frontmatter.purchaseSteps}
        subheading={frontmatter.subheading}
        desktopLogo={desktopLogo}
        mobileLogo={mobileLogo}
        groundFloorDesktop={groundFloorDesktop}
        groundFloorMobile={groundFloorMobile}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    desktopLogo: file(relativePath: { eq: "static/main-logo-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileLogo: file(relativePath: { eq: "static/main-logo-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    groundFloorDesktop: file(
      relativePath: { eq: "static/home-ground-floor-desktop.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    groundFloorMobile: file(
      relativePath: { eq: "static/home-ground-floor-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        intro {
          sectionOne
          sectionTwo
        }
        disclaimer {
          heading
          description
        }
        purchaseSteps {
          heading
          description
          blurbs {
            text
          }
        }
      }
    }
  }
`;
