import React from "react";
import PropTypes from "prop-types";

import "./style.sass";

import stepOne from "../../img/step1_icon.svg";
import stepTwo from "../../img/step2_icon.svg";
import stepThree from "../../img/step3_icon.svg";

const stepImages = [stepOne, stepTwo, stepThree];

const FeatureGrid = ({ gridItems }) => (
  <div className="features">
    {gridItems.map((item, i) => (
      <section key={item.text} className="step-itm">
        <div className="image">
          <div>
            <img src={stepImages[i]} alt="" />
          </div>
        </div>
        <div className="text">
          <h5>Step {i + 1}</h5>
          <p>{item.text}</p>
        </div>
      </section>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string
    })
  )
};

export default FeatureGrid;
